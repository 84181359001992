<template>
  <v-form lazy-validation @submit.prevent>
    <v-sheet class="elevation-0 form-wrapper pa-0">
      <v-card-title class="title font-weight-regular justify-space-between">
        <h1 class="title font-weight-medium white--text">{{ currentTitle }}</h1>
        <v-avatar
          v-if="step !== 5 && step !== 4"
          color="grey darken-2"
          class="body-2 white--text"
          size="36"
          v-text="step + '/3'"
        />
      </v-card-title>

      <v-window v-model="step" touchless>
        <v-window-item :value="1">
          <v-card-text>
            <v-text-field
              v-model="email"
              name="email"
              label="Email"
              type="email"
              :rules="emailRules"
              class="text-fields-theme"
              autocomplete="email"
              outlined
              required
              :autofocus="$vuetify.breakpoint.smAndUp ? true : false"
            />
            <span class="caption white--text text--lighen">
              Selle e-mailiga saate järgmine kord sisse logida
            </span>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-text>
            <v-text-field
              v-model="password"
              name="password"
              :label="$vuetify.lang.t('$vuetify.general.password')"
              counter
              autocomplete="password"
              required
              outlined
              class="text-fields-theme"
              :rules="passwordRules"
              :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
              :type="passwordVisible ? 'text' : 'password'"
              :autofocus="$vuetify.breakpoint.smAndUp ? true : false"
              @click:append="() => (passwordVisible = !passwordVisible)"
            />
            <v-text-field
              v-model="confirmPassword"
              name="confirmPassword"
              class="text-fields-theme"
              :label="$vuetify.lang.t('$vuetify.general.confirmPassword')"
              required
              outlined
              autocomplete
              :rules="[comparePasswords]"
              :append-icon="confirmPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
              :type="confirmPasswordVisible ? 'text' : 'password'"
              @click:append="
                () => (confirmPasswordVisible = !confirmPasswordVisible)
              "
            />
            <span class="caption grey--text text--darken-1">
              {{ $vuetify.lang.t('$vuetify.registerForm.passwordInfo') }}
              <span class="font-weight-bold">
                6
              </span>
              {{ $vuetify.lang.t('$vuetify.registerForm.charactersLong') }}
            </span>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="3">
          <v-card-text>
            <v-text-field
              v-model="firstName"
              class="text-fields-theme"
              :label="$vuetify.lang.t('$vuetify.general.firstName')"
              required
              outlined
              :rules="firstNameRules"
              autocomplete
            />
            <v-text-field
              v-model="lastName"
              class="text-fields-theme"
              :label="$vuetify.lang.t('$vuetify.general.lastName')"
              required
              outlined
              :rules="lastNameRules"
              autocomplete
            />
            <!-- <span class="caption grey--text text--darken-1">
              {{ $vuetify.lang.t('$vuetify.registerForm.personalDetailsInfo') }}
            </span> -->
            <v-checkbox
              v-model="acceptedPrivacyPolicy"
              class="checkbox-field-theme"
            >
              <template v-slot:label>
                <div @click.stop="">
                  Nõustun
                  <a
                    href="javascript:;"
                    @click.stop="privacyPolicyDialog = true"
                  >
                    privaatsuspoliitikaga
                  </a>
                  .
                </div>
              </template>
            </v-checkbox>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="4">
          <div class="pa-3 text-center">
            <v-img
              class="mb-3"
              contain
              height="128"
              :src="require('@/assets/img/Piletivahetuse_logo_valge.svg')"
              :lazy-src="require('@/assets/img/Piletivahetuse_logo_valge.svg')"
            />
            <h3 class="title font-weight-light mb-2">
              Nii tore, et siin olete!
            </h3>
            <span class="caption grey--text">
              Me oleme kindlad, et Teile siin meeldib!
            </span>
          </div>
        </v-window-item>
      </v-window>

      <v-card-actions>
        <v-btn
          :disabled="step === 1 || step === 4"
          text
          class="white--text rounded-10"
          @click="step--"
        >
          {{ this.$vuetify.lang.t('$vuetify.general.back') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="step === 1"
          color="white"
          background-color="white"
          :disabled="!isValidEmail"
          class="white--text rounded-10 pl-5 pr-5"
          outlined
          depressed
          @click="step++"
        >
          {{ this.$vuetify.lang.t('$vuetify.general.next') }}
        </v-btn>
        <v-btn
          v-if="step === 2"
          color="white"
          :disabled="
            isPasswordEmpty ||
              isConfirmPasswordEmpty ||
              !passwordMatch ||
              password.length < 6
          "
          class="white--text rounded-10 pl-5 pr-5"
          outlined
          depressed
          @click="step++"
        >
          {{ $vuetify.lang.t('$vuetify.general.next') }}
        </v-btn>
        <v-btn
          v-if="step === 3"
          :disabled="
            step === 4 || !firstName || !lastName || !acceptedPrivacyPolicy
          "
          :loading="loading.state === true && loading.type === 'register'"
          color="white"
          class="white--text rounded-10 pl-5 pr-5"
          outlined
          depressed
          @click="signUp()"
        >
          {{ $vuetify.lang.t('$vuetify.general.signup') }}
        </v-btn>
      </v-card-actions>
    </v-sheet>
    <privacy-policy-dialog
      :visible="privacyPolicyDialog"
      @close="privacyPolicyDialog = false"
      @didAcceptPolicy="acceptedPrivacyPolicy = true"
    />
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
    PrivacyPolicyDialog: () =>
      import(
        /* webpackChunkName: "PrivacyPolicyDialog" */ '@/components/PrivacyPolicyDialog'
      )
  },
  data() {
    return {
      valid: true,
      step: 1,
      emailRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.registerForm.emailRequired'),
        () =>
          this.isValidEmail ||
          this.$vuetify.lang.t('$vuetify.registerForm.emailMustBeValid')
      ],
      passwordRules: [
        v =>
          v.length >= 6 ||
          this.$vuetify.lang.t('$vuetify.registerForm.minSixCharacters')
      ],
      firstNameRules: [
        v =>
          v.length >= 2 ||
          this.$vuetify.lang.t('$vuetify.registerForm.minTwoCharacters')
      ],
      lastNameRules: [
        v =>
          v.length >= 2 ||
          this.$vuetify.lang.t('$vuetify.registerForm.minTwoCharacters')
      ],
      passwordVisible: false,
      confirmPasswordVisible: false,
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      acceptedPrivacyPolicy: false,
      privacyPolicyDialog: false
    }
  },
  computed: {
    ...mapGetters('app', ['error', 'loading']),
    isValidEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(this.email).toLowerCase())
    },
    isPasswordEmpty() {
      return !this.password.replace(/^\s+/g, '').length
    },
    isConfirmPasswordEmpty() {
      return !this.confirmPassword.replace(/^\s+/g, '').length
    },
    passwordMatch() {
      return this.password === this.confirmPassword
    },
    comparePasswords() {
      return this.password === this.confirmPassword
        ? true
        : this.$vuetify.lang.t('$vuetify.registerForm.passwordsDontMatch')
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return this.$vuetify.lang.t('$vuetify.general.signup')
        case 2:
          return this.$vuetify.lang.t('$vuetify.registerForm.createPassword')
        case 3:
          return this.$vuetify.lang.t('$vuetify.registerForm.personalDetails')
        case 4:
          return this.$vuetify.lang.t('$vuetify.registerForm.accountCreated')
        default:
          return ''
      }
    }
  },
  methods: {
    focusOnView(event) {
      switch (event.keyCode) {
        case 13:
          if (this.step === 1 && this.isValidEmail) {
            this.step += 1
          } else if (
            this.step === 2 &&
            !this.isPasswordEmpty &&
            !this.isConfirmPasswordEmpty &&
            this.passwordMatch
          ) {
            this.step += 1
          } else if (this.step === 3 && this.firstName && this.lastName) {
            this.signUp()
          }
          break
        default:
          break
      }
    },
    async signUp() {
      try {
        await this.$store.dispatch('authentication/register', {
          email: this.email,
          password: this.password,
          firstName: this.firstName,
          lastName: this.lastName,
          photoURL: `https://www.gravatar.com/avatar/${this.email
            .trim()
            .toLowerCase()}?s=200&r=g&d=retro`
        })
        this.step += 1
        if (this.$router.currentRoute.name === 'register') {
          setTimeout(() => {
            this.$router.push('/')
          }, 3000)
        }
      } catch {
        this.email = ''
        this.step = 1
        this.$store.commit('app/setError', null, { root: true })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.form-wrapper.theme--light.v-sheet {
  background-color: rgba(255, 255, 255, 0);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 36px;
}

::v-deep .text-fields-theme .v-input__slot {
  background: #ffffff !important;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  border: 0 !important;
}

::v-deep .checkbox-field-theme {
  i {
    color: #ffffff;
  }
  .v-label {
    color: #ffffff !important;
  }
}
</style>
